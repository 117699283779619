<template>
  <aside class="dashboard-panel tag-panel" :class="{ active: sidebar }">
    <main-navigation :items="menu" v-model="conditions.blocks" />
    <tag-navigation :items="tags" v-model="conditions.tags" />
    <section class="app-tools">
      <u-button variant="circle" ariaLabel="Theme" @click="globalState.toggleTheme()">
        <u-icon :name="globalState.getTheme() === 'light' ? 'moon-inv' : 'sun-inv'" />
      </u-button>
      <u-button variant="circle" ariaLabel="GitHub" @click="openGitHub()">
        <u-icon name="github" />
      </u-button>
    </section>
  </aside>
  <section class="dashboard-panel block-panel" :class="{ hide: block }">
    <block-tools v-model:sort="conditions.sort" v-model:term="conditions.term" @block:create="createBlock"
      @navigation:toggle="sidebar = !sidebar" />
    <u-scroll @scroll:end="paginate" :limit="100" ref="scroll">
      <block-list :items="blocks" @blocks:delete="deleteBlock" v-model:selected="block" />
    </u-scroll>
  </section>
  <section class="dashboard-panel code-panel" :class="{ hide: !block }">
    <code-editor v-model="block" :theme="globalState.getTheme()" @block:delete="deleteBlock"
      @block:close="block = false" v-if="block" />
  </section>
  <section class="dashboard-panel llm-panel" :class="{ hide: block }">
    <llm-block :items="blocks" v-model:selected="block" />
  </section>
  <section class="dashboard-panel chain-panel" :class="{ hide: !block }">
    <chain-builder />
  </section>
  <u-notify />
</template>

<script setup>
import { onMounted, reactive, ref, toRaw, watch, onErrorCaptured, inject } from 'vue';
import setupTheme from '@/helpers/themeSwitcher';
import CodeEditor from '@/components/app/CodeEditor.vue';
import TagNavigation from '@/components/app/TagNavigation.vue';
import MainNavigation from '@/components/app/MainNavigation.vue';
import BlockTools from '@/components/app/BlockTools.vue';
import initStorage from '@/storage/db/idb';
import menu from '@/data/menu';
import { blockEntity } from '@/data/blockEntity';
import BlockStorage from '@/storage/block';
import globalState from '@/globalState';
import ChainBuilder from '@/components/app/ChainBuilder.vue';
import LlmBlock from '@/components/app/LlmBlock.vue';
import BlockList from '@/components/app/BlockList.vue';

const notify = inject('notify');
const sidebar = ref(false);
const defaultConditions = {
  blocks: 'all',
  tags: [],
  sort: 'desc',
  term: '',
};
const conditions = reactive({ ...defaultConditions });
const tags = ref([]);
const block = ref(false);
const blocks = ref([]);
const scroll = ref({});


try {
  await initStorage();
} catch (e) {
  notify.error(e);
}

const blockStorage = new BlockStorage();

const getTags = async () => {
  try {
    tags.value = await blockStorage.tags();
    // refresh conditions
    conditions.tags = conditions.tags.filter((value) => tags.value.includes(value));
  } catch (e) {
    notify.error(e);
  }
};

const createBlock = async () => {
  try {
    const response = await blockStorage.create(blockEntity);
    blocks.value.unshift(...response);
    block.value = blocks.value[0] ?? false;
    scroll.value.scrollUp();
    // reset filter conditions
    Object.assign(conditions, defaultConditions);
  } catch (e) {
    notify.error(e);
  }
};

const deleteBlock = async (entity) => {
  try {
    await blockStorage.softDelete(entity);
    blocks.value.splice(blocks.value.map((item) => item.id).indexOf(entity.id), 1);
    block.value = null;
    getTags();
  } catch (e) {
    notify.error(e);
  }
};

const paginate = async (skip) => {
  try {
    blocks.value.push(...(await blockStorage.search(toRaw(conditions), skip, 100)));
  } catch (e) {
    notify.error(e);
  }
};

const openGitHub = () => window.open('https://gist.github.com/mediamichael/', '_blank');

watch(
  block,
  async (current, previous) => {
    if (Object.is(current, previous) && current.id === previous.id) {
      const data = toRaw(block.value);
      await blockStorage.update(data);
      getTags();
    }
  },
  {
    deep: true,
  }
);
watch(
  conditions,
  async () => {
    blocks.value = await blockStorage.search(toRaw(conditions));
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(
  () => globalState.getTheme(),
  (value) => setupTheme(value),
  { immediate: true }
);

onMounted(() => {
  getTags();
});

onErrorCaptured((e) => {
  notify.error(e);
});
</script>

<style>
html,
body {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  color: var(--default-text-color);
  background: var(--body-bg);
}

#app {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.dashboard-panel {
  display: flex;
  flex-direction: column;
}

.tag-panel {
  max-width: 210px;
  min-width: 210px;
  transition: margin 0.3s;
  background: var(--aside-bg-color);
  height: 100%;
  padding-left: 16px;
  padding-right: 24px;
}
.tag-panel.active {
  margin-left: -250px;
}

.block-panel {
  min-width: 300px;
  flex: 1;
  color: var(--default-text-color);
  background: var(--body-bg);
  border-right: var(--blocks-border-right);
  border-left: var(--blocks-border-left);
  padding-left: 16px;
  padding-right: 24px; 
}

.llm-panel {
  flex: 1;
  min-width: 228px;
  padding: 0 16px;
}

.code-panel {
  background: var(--body-bg);
  flex-grow: 2;
  max-width: 1280px;
}

.chain-panel {
  height: 100%;
  min-width: 360px;
  padding: 24px 0;
  flex-grow: 2;
}

.app-tools {
  padding: 5px;
  display: inline-flex;
  justify-content: right;
  gap: 5px;
}

/** overwrite  **/
.ͼ1.cm-editor {
  height: 100%;
}

:focus-visible {
  outline: none;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.cm-focused {
  outline: none !important;
}

/** responsive things **/

.m-button {
  display: none;
}

@media (max-width: 1600px) {
  .block-panel {
    max-width: 280px;
  }
  .code-panel {
    flex-grow: 1;
  }
  .chain-panel {
    flex-grow: 1;
  }
}

@media (max-width: 1280px) {

}

@media (max-width: 980px) {

}

@media (max-width: 650px) {
  .tag-panel.active {
    margin-left: 0;
  }

  .tag-panel {
    margin-left: -210px;
  }

  .hide {
    display: none;
  }

  .block-panel {
    width: 100%;
  }

  .m-button {
    display: inline-flex;
  }
}
</style>
