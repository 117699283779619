<template>
  <div class="progress-bar">
    <div v-if="isLoading" class="track">
      <div class="bar" :style="{ width: progress }"></div>
    </div>
    <p v-if="isLoading" class="title">{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.progress-bar,
.track {
  width: 100%;
}

.progress-bar {
  height: 40px;
}

.track {
  background-color: transparent;
  height: 4px;
}

.bar {
  height: 4px;
  background-color: #ffb300;
  transition: width 15s ease-out;
}

.title {
  color: white;
  font-size: 10px;
  margin: 12px;
}
</style>