<template>
  <div class="chain">
    <!-- <select v-model="selectedBlock">
      <option v-for="block in blocks" :key="block.id">{{ block.title }}</option>
    </select> -->
    <!-- Render the text inputs dynamically from the JSON object -->
    <text-input
      v-for="(inputBlock, key) in textInputs"
      :key="key"
      v-model:input="inputBlock.input"
      :label="inputBlock.label"
      :ariaInput="inputBlock.ariaInput"
    />
    <button @click="addTextInput" class="circle-button">
      <span class="plus-sign">+</span>
    </button>

    <!-- Render the chain blocks dynamically from the JSON object -->
    <chain-block
      v-for="(block, key) in chainBlocks"
      :key="key"
      :title="block.title"
      :label="block.label"
      :response="block.response"
    />
    <button @click="addChainBlock" class="circle-button">
      <span class="plus-sign">+</span>
    </button>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import ChainBlock from '@/components/app/ChainBlock.vue';
import TextInput from '@/components/app/TextInput.vue';

defineProps({
  items: {
    type: Array,
  },
  selected: {},
  block: {
    type: Object,
  },
});
defineEmits(['update:selected', 'blocks:delete']);
// const blocks = ref([]);
const selectedBlock = ref(null);

watch(selectedBlock, (newBlock) => {
  console.log('Selected block:', newBlock);
});

// Initial JSON structure for text inputs
const textInputs = ref({
  "input-1": {
    input: "",
    label: "D1",
    ariaInput: "The input for the model"
  }
});

// Initial chain block configuration using JSON object
const chainBlocks = ref({
  "block-1": {
    title: "Brief Chain",
    label: "D1",
    response: "This is the brief."
  }
});

// Function to generate a unique ID for each text input
const generateTextInputId = () => `input-${Object.keys(textInputs.value).length + 1}`;

// Function to add a new text input
const addTextInput = () => {
  const newInputId = generateTextInputId();
  textInputs.value[newInputId] = {
    input: "",
    label: `D${Object.keys(textInputs.value).length + 1}`,
    ariaInput: `The input for the model ${Object.keys(textInputs.value).length + 1}`
  };
};

// Function to generate a unique ID for each chain block
const generateBlockId = () => `block-${Object.keys(chainBlocks.value).length + 1}`;

// Function to add a new chain block
const addChainBlock = () => {
  const newBlockId = generateBlockId();
  chainBlocks.value[newBlockId] = {
    title: `New Block ${Object.keys(chainBlocks.value).length + 1}`,
    label: `D${Object.keys(chainBlocks.value).length + 1}`,
    response: `Response for block ${Object.keys(chainBlocks.value).length + 1}`
  };
};
</script>

<style scoped>
.chain {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.circle-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #ffffff; /* Stroke color */
  background-color: rgba(255, 255, 255, 0); /* Transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px; /* Spacing between buttons */
}

.circle-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Background color on hover */
}

.circle-button:active {
  transform: scale(0.95); /* Slightly shrink the button when pressed */
}

.plus-sign {
  font-size: 24px; /* Size of the plus sign */
  color: #ffffff; /* Plus sign color */
  transition: color 0.3s ease;
}

.circle-button:hover .plus-sign {
  color: #ffffff; /* Change plus sign color on hover */
}
</style>
