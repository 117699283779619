import { DATA_TYPE } from 'jsstore';
import connection from './connection';
import { welcomeBlock } from '../../data/blockEntity';
import * as backupBlocks from '../../data/backup_db';

const getDb = () => {
  const tblBlocks = {
    name: 'blocks',
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
        dataType: DATA_TYPE.Number,
      },
      access_level: {
        notNull: true,
        dataType: DATA_TYPE.Number,
        default: 0,
      },
      title: {
        notNull: true,
        dataType: DATA_TYPE.String,
        default: 'Untitled',
        enableSearch: true,
      },
      language: {
        notNull: true,
        dataType: DATA_TYPE.String,
        enableSearch: true,
      },
      code: {
        dataType: DATA_TYPE.String,
        enableSearch: false,
      },
      tags: {
        dataType: DATA_TYPE.Array,
        multiEntry: true,
        default: [],
      },
      editor_options: {
        dataType: DATA_TYPE.Object,
        default: {},
      },
      favorite: {
        notNull: true,
        dataType: DATA_TYPE.Number,
        default: 0,
      },
      remote_id: {
        dataType: DATA_TYPE.Number,
        default: null,
      },
      last_sync: {
        dataType: DATA_TYPE.String,
        default: null,
      },
      created_at: {
        dataType: DATA_TYPE.String,
        notNull: true,
        enableSearch: true,
      },
      updated_at: {
        dataType: DATA_TYPE.String,
        notNull: true,
        enableSearch: true,
      },
      deleted: {
        notNull: true,
        dataType: DATA_TYPE.Number,
        default: 0,
      },
    },
  };

  const database = {
    name: 'blocks_db',
    version: 2,
    tables: [tblBlocks]
  };
  return database;
};

function importBackup() {
  console.log('importBackup: ');
  let backup = {};
  backup = backupBlocks.default;
  console.log('idb.js: importing backup_db.js', backup);
  for (const key in backup) {
    if (Object.hasOwn(backup, key)) {
      connection.insert({
        into: key,
        values: backup[key]
      });
    }
  }
}

function dateYYYYMMDD(date = new Date()) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

function downloadJSON(data) {
  const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  const date = dateYYYYMMDD();
  a.download = `backup_db_${date}.json`;
  a.click();
  URL.revokeObjectURL(url);
}


const initStorage = async () => {
  const newDb = await connection.initDb(getDb());
  // if existing db opened 
  if (!newDb) {
    // load backup *** REMOVE AFTER NEW DEPLOYMENT ***
    // importBackup();

    // Download data *** REMOVE AFTER DOWNLOADING ***
    // const results =  await connection.select({
    //   from:'blocks'
    // });
    // downloadJSON(results);
  }
  
  // if no database
  if (newDb === true) {
    await connection.insert({
      into: 'blocks',
      values: [welcomeBlock],
      return: true,
    });
  }

  return newDb;
};

export default initStorage;
