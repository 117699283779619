<template>
  <button v-if="responseData" :fileName="fileName" @click="downloadFile">Download</button>
</template>

<script>
export default {
  data() {
    return {
      responseData: null, // This should be populated with your data
      fileName: 'defaultName'
    };
  },
  methods: {
    downloadFile() {
      const blob = new Blob([this.responseData], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${this.fileName}.txt`; // Using template literal
      link.click();
      window.URL.revokeObjectURL(link.href); // Clean up the URL.createObjectURL
    }
  }
};
</script>
