<template>
  <div class="container">
    <div class="action-bar">
      <ProgressBar :progress="progress" :message="loadingMsg" :isLoading />
      <DownloadChip v-if="responseData" :fileName="fileName" />
      <button @click="runWorkflow">Run Workflow</button>
      <button @click="createImages(selectedCode)">Create Images</button>
      <button @click="generateVideos()">Create Shorts</button>
      <!-- <button @click="downloadAllImages">Download All</button> -->
    </div>
    <div class="content">
      <h3>Selected block ID: {{ selectedId }}</h3>
      <code>{{ selectedCode }}</code>
      <p v-if="responseData">{{ responseData }}</p>
      <div class="gallery" v-if="images.length > 0">
        <img v-for="(image, index) in images" :key="index" :src="image.url" :alt="image.prompt" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { watchEffect, ref, defineProps, defineEmits } from 'vue';
import ProgressBar from '@/components/base/ProgressBar.vue';
import DownloadChip from '@/components/base/DownloadChip.vue';
import OpenAI from 'openai';
import { LLMChain } from 'langchain/chains';
import { ChatGoogleGenerativeAI } from '@langchain/google-genai';
import { HarmBlockThreshold, HarmCategory } from '@google/generative-ai';
import { ChatOpenAI, DallEAPIWrapper } from '@langchain/openai';
import { PromptTemplate, ChatPromptTemplate } from '@langchain/core/prompts';
import Replicate from 'replicate';

const props = defineProps({
  items: {
    type: Array,
  },
  selected: {
    type: Object,
    default: () => ({}), // Fallback to an empty object
  },
  block: {
    type: Object,
  },
});

defineEmits(['update:selected', 'blocks:delete']);

const selectedBlocks = ref(props.selected);
const selectedId = ref('');
const selectedCode = ref('');
const images = ref([]);
const isLoading = ref(false);
const progress = ref('');
const loadingMsg = ref('');
const responseData = ref({});
const fileName = ref('File');
let imageStyling = ref('');

watchEffect(() => {
  selectedBlocks.value = props.selected; // Sync with prop value
  console.log('Selected block updated:', selectedBlocks.value);
  selectedId.value = selectedBlocks.value ? selectedBlocks.value.id : '';
  console.log('selectedId: ', selectedId);
  selectedCode.value = selectedBlocks.value ? selectedBlocks.value.code : '';
});

function isArr(value) {
  const arr = Array.isArray(value);
  console.log('isArray: ', arr);
  return arr;
}

function isString(value) {
  const str = typeof value === 'string' || value instanceof String;
  console.log('isString: ', str);
  return str;
}

function formatToArray(data) {
  console.log('formatToArray: ', data);
  let dataArray = [];

  if (isString(data)) {
    console.log('formatToArray: String');
    try {
      // Attempt to parse the string as JSON
      const parsedData = JSON.parse(data);

      if (isArr(parsedData)) {
        console.log('formatToArray: Parsed JSON Array');
        dataArray = parsedData;
      } else {
        console.log('formatToArray: Parsed JSON Single Item');
        dataArray = [parsedData];
      }
    } catch (e) {
      console.log('formatToArray: Non-JSON String');
      dataArray = [data];
    }
  } else if (isArr(data)) {
    console.log('formatToArray: Already an Array');
    dataArray = data;
  } else {
    console.log('formatToArray: Single Item');
    dataArray = [data];
  }

  console.log('formatToArray: dataArray: ', dataArray);
  return dataArray;
}

function extractResponseData(response, modelType) {
  // TODO: In future use Base Class with Response Handlers to handle different types of responses from various AI models
  let responseNode;
  switch (modelType) {
    case 'gemini-pro': // TODO: assumes text
      responseNode = response.text;
      break;
    case 'dall-e-3':
      responseNode = response; // url
      break;
    case 'chatgpt': // TODO: assumes conversation text
      if (response.choices && response.choices.length > 0) {
        responseNode = response.choices[0].message.content;
      }
      break;
    default:
      console.warn('Unknown model type:', modelType);
      responseNode = response;
      break;
  }
  return responseNode;
}

function getBlocksById(id) {
  console.log('getBlocksById: id:', id);
  if (!props.items || props.items.length === 0) {
    console.warn('getBlocksById called, but items array is empty or not defined');
    return null;
  }
  let blockContent = {};
  blockContent = props.items.find((block) => block.id === id).code;

  if (!blockContent) {
    console.warn(`No block found with id: ${id}`);
  }
  return blockContent;
}

function createModel(config) {
  console.log('createModel: ');
  console.log('createModel: config: ', config);

  let model;
  const modelConfig = { ...config };
  console.log('modelConfig.name: ', modelConfig.name);
  if (config.name === 'gemini') {
    modelConfig.apiKey = process.env.VUE_APP_GEMINI_API_KEY;
    model = new ChatGoogleGenerativeAI(modelConfig);
  } else if (config.name === 'chatGPT') {
    modelConfig.apiKey = process.env.VUE_APP_CHATGPT_API_KEY;
    model = new ChatOpenAI(modelConfig);
  } else if (config.name === 'openai') {
    modelConfig.organization = process.env.VUE_APP_OPENAI_ORG_ID;
    modelConfig.project = process.env.VUE_APP_OPENAI_PROJ_ID;
    modelConfig.apiKey = process.env.VUE_APP_OPENAI_API_KEY;

    modelConfig.dangerouslyAllowBrowser = true;
    model = new OpenAI(modelConfig);
  } else if (config.name === 'dall-e-3') {
    modelConfig.organization = process.env.VUE_APP_DALLE_ORG_ID;
    modelConfig.project = process.env.VUE_APP_DALLE_PROJ_ID;
    modelConfig.apiKey = process.env.VUE_APP_DALLE_API_KEY;

    // modelConfig.dangerouslyAllowBrowser = true;
    // modelConfig.max_tokens = 8191,
    // modelConfig.top_p = 1,
    // modelConfig.frequency_penalty = 0,
    // modelConfig.presence_penalty = 0,
    // modelConfig.response_format = {
    //     "type": "json_object"
    // },
    model = new DallEAPIWrapper(modelConfig);
  } else if (config.name === 'flux-dev') {
    // TODO configure for any Replicate model
    modelConfig.base = 'xlabs-ai/flux-dev-realism';
    modelConfig.version = '39b3434f194f87a900d1bc2b6d4b983e90f0dde1d5022c27b52c143d670758fa';
    modelConfig.model =
      'xlabs-ai/flux-dev-realism:39b3434f194f87a900d1bc2b6d4b983e90f0dde1d5022c27b52c143d670758fa';
    modelConfig.replicateId = process.env.VUE_APP_REPLICATE_ID;
    modelConfig.apiKey = process.env.VUE_APP_REPLICATE_API_KEY;
    model = new Replicate(modelConfig.model);
  } else {
    console.log('createModel: Error model name not found: ', config.name);
  }
  return model;
}

function getInputValues(input, responses, promptKeys, messages) {
  console.log('getInputValues: ', { input, messages });

  const inputValues = {};

  if (input.text) {
    // TODO: input for the first Step should be from an input component, not defined in the workflow.json
    inputValues[promptKeys[0]] = input.text;
  } else {
    const refKey = promptKeys[0];
    inputValues[refKey] = responses[refKey];
  }

  console.log('getInputValues: inputValues', inputValues);
  return inputValues;
}

async function buildChain(llm, template, responses, promptKeys, input) {
  console.log('buildChain: ');
  const promptTemplate = typeof template === 'string' ? JSON.parse(template) : template;
  const messages = Array.isArray(promptTemplate.messages)
    ? promptTemplate.messages
    : formatToArray(promptTemplate.messages);
  const inputValues = getInputValues(input, responses, promptKeys, messages);

  const chainTemplate = ChatPromptTemplate.fromMessages(messages);
  const chain = new LLMChain({ llm, prompt: chainTemplate });
  const response = await chain.invoke(inputValues);

  console.log('buildChain: response: ', response);
  return response;
}

async function runBranch(branch, llm, responses, stepName) {
  console.log('runBranch: ', stepName);

  const branchInputs = branch.input || [];
  await Promise.all(
    branchInputs.map(async (input) => {
      // Destructure the input to get templateId
      const { templateId } = input;

      // Fetch the template using the templateId
      let promptTemplate = getBlocksById(templateId);
      if (typeof promptTemplate === 'string') {
        promptTemplate = JSON.parse(promptTemplate);
      }

      // Get the keys for the template's input values
      const promptInputKeys = input.references || promptTemplate.inputKeys;

      // Build the chain and invoke it
      const response = await buildChain(llm, promptTemplate, responses, promptInputKeys, input);

      // Determine the correct node in the response based on model type
      Object.assign(responses, { [stepName]: extractResponseData(response, input.model) });

      // Handle nested branches, if any
      if (branch.branch) {
        await runBranch(branch.branch, llm, responses, stepName);
      }
    })
  );

  console.log('runBranch: responses: ', responses);
}

async function runWorkflow() {
  console.log('runWorkflow: ');
  const workflowJson = selectedCode.value;
  isLoading.value = true;
  progress.value = '3%';
  loadingMsg.value = 'Loading workflow...';

  const blockFlow = typeof workflowJson === 'string' ? JSON.parse(workflowJson) : workflowJson;
  const steps = Array.isArray(blockFlow.steps) ? blockFlow.steps : formatToArray(blockFlow.steps);
  console.log('runWorkflow: ', { workflowJson, blockFlow });
  if (!steps.length) {
    isLoading.value = false;
    return;
  }

  const numSteps = steps.length;
  const responses = {};

  loadingMsg.value = 'Creating AI models...';
  progress.value = '5%';

  for (let i = 0; i < numSteps; i += 1) {
    const step = steps[i];
    const stepName = steps[i].name;
    loadingMsg.value = `${stepName}... ${i + 1} of ${numSteps}`;
    progress.value = `${Math.round(i / numSteps)}%`;

    const modelConfig = step.modelConfig || {};
    const llm = createModel(modelConfig);

    const stepInputs = step.input[0]; // Adjust if multiple inputs are needed
    let templateId = 0;
    templateId = stepInputs.templateId;
    console.log('runWorkflow: loop: ', { step, modelConfig, stepInputs, templateId });
    let promptTemplate = getBlocksById(templateId);
    if (typeof promptTemplate === 'string') {
      promptTemplate = JSON.parse(promptTemplate);
    }

    const stepKeys = stepInputs.references || promptTemplate.inputKeys;
    const response = await buildChain(llm, promptTemplate, responses, stepKeys, stepInputs);

    responses[stepName] = extractResponseData(response, modelConfig.model);
    responseData.value[stepName] = { ...response };

    // Handle branches/maps if needed
    if (step.branch) {
      for (const branch of step.branch) {
        await runBranch(branch, llm, responses, stepName);
      }
    }
  }

  isLoading.value = false;
  progress.value = '100%';
  loadingMsg.value = 'Workflow completed!';
}

async function createImages({ imgPromptArray, brandBlockId, modelConfig }) {
  console.log(
    'createImages: imgPromptArray, brandBlockId, modelConfig: ',
    imgPromptArray,
    brandBlockId,
    modelConfig
  );
  let imgPrompt;
  let config;
  let brandId;
  let brandStyle;
  if (!imgPromptArray) {
    const imagesConfig = selectedCode.value;
    const imagesConfigObj =
      typeof imagesConfig === 'string' ? JSON.parse(imagesConfig) : imagesConfig;
    imgPrompt = imagesConfigObj.imgPrompt;
    config = imagesConfigObj.config;
    brandId = imagesConfigObj.brandId;
  }
  if (!config) config = { name: 'dall-e-3', size: '1024x1792', n: 1, temperature: 0 };
  if (brandId) {
    brandStyle = getBlocksById(brandId).replace(/\n\s+/g, ' ');
    console.log('createImages: brandStyle ', brandStyle);
    imageStyling = brandStyle;
  } else {
    brandStyle = ` Hyper Candid shot. Hyper Realistic. Shallow depth of field. High-resolution DSLR camera.`;
  }

  isLoading.value = true;
  loadingMsg.value = 'Loading data...';
  progress.value = '5%';

  let prompts = [];
  if (!isArr(imgPrompt)) {
    prompts = formatToArray(imgPrompt);
  } else {
    prompts = imgPrompt;
  }
  if (!prompts.length) {
    return;
  }
  const arrNum = prompts.length;
  console.log('createImages: arrNum: ', arrNum);

  const model = createModel(config);

  progress.value = '6%';
  loadingMsg.value = `Processing ${arrNum} prompts...`;

  let currentImage = 0;
  for (const prompt of prompts) {
    currentImage = 1 + currentImage;
    progress.value = `${(currentImage / arrNum) * 100}%`;
    loadingMsg.value = `Generating Image ${currentImage} / ${arrNum}`;
    const fullPrompt = prompt + brandStyle;
    console.log('createImages: fullPrompt: ', fullPrompt);
    try {
      let response;
      if (config.name === 'openai') {
        response = await model.images.generate({
          model: 'dall-e-3',
          prompt: fullPrompt,
        });
      } else if (config.name === 'flux-dev') {
        config.prompt = fullPrompt;
        response = await model.run({ input: config });
      } else {
        response = await model.invoke(fullPrompt);
      }
      if (response.url) {
        // TODO: Find urls, if modelConfig.n > 1.
        images.value.push({ url: response.url, fullPrompt });
      } else if (response.data) {
        images.value.push({ url: response.data[0].url, prompt: response.data[0].revised_prompt });
      } else {
        // TODO: if response is string and starts with http
        images.value.push({ url: response, fullPrompt });
      }
    } catch (error) {
      console.log('Error generating image:', error);
      // wait 8 minutes and resend
      setTimeout(createImages, 480000, prompt, config);
    }
  }
  // }
  console.log('createImages: Complete');
  // TODO: create and log our own genId, ex. const genId = { prompt: {promptText, promptStyles, imagePrompt}, seeds, referenced_image_ids }

  progress.value = '';
  loadingMsg.value = '';
  isLoading.value = false;
  // return images;
}

function downloadAllImages() {
  images.value.forEach((image) => {
    const link = document.createElement('a');
    link.href = image.url;
    link.download = `image_${image.index}_${Date.now()}.jpg`;
    link.click();
  });
}

async function generateVideos(videoArray, config) {
  console.log('generateVideos: videoArray, config: ', videoArray, config);
  let videoObj;
  let promptMethod;
  let videos = [];
  if (!videoArray) {
    const codeBlock = selectedCode.value;
    const codeObj = typeof codeBlock === 'string' ? JSON.parse(codeBlock) : codeBlock;
    videos = codeObj.videos;
    if (codeObj.config) {
      const configObj =
        typeof codeObj.config === 'string' ? JSON.parse(codeObj.config) : codeObj.config;
      console.log('generateVideos: configObj: ', configObj);
      promptMethod = configObj.promptType;
    }
  }
  const apiBaseUrl = 'https://video-ai.invideo.io/api/copilot/request/chatgpt-new-from-';
  let apiUrl;
  if (promptMethod) {
    apiUrl = apiBaseUrl + promptMethod;
  } else {
    apiUrl = `${apiBaseUrl}script`;
  }

  for (const video of videos) {
    if (video.brief) {
      apiUrl = `${apiBaseUrl}brief`;
    } else if (video.script) {
      apiUrl = `${apiBaseUrl}script`;
    }
    const { platforms } = video;
    for (let i = 0; i < platforms.length; i += 1) {
      video.platforms = [platforms[i]];
      if (video.settings.includes('youtube shorts')) {
        video.settings = video.settings.replace('youtube shorts', platforms[i]);
      } else if (video.settings.includes('youtube video')) {
        video.settings = video.settings.replace('youtube video', platforms[i]);
      }
      // console.log('generateVideos: video.platforms: ', video.platforms );
      // console.log('generateVideos: video.settings: ', video.settings );
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(video),
        });
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        if (data.video_url) {
          console.log(data.video_url);
          // responseData.value[video.title] = { ...data };
          responseData.value += `         ${data.video_url}`;
        } else {
          console.error('No video URL found in response', data);
        }
      } catch (error) {
        console.error('Failed to generate video:', error);
      }
    }
  }
  // }
  // return videoUrls;
}
</script>
<style scoped>
.container,
.content,
.gallery,
.action-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  overflow-y: auto;
}

.action-bar {
  align-items: center;
  gap: 16px;
}

.content {
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 16px;
}

.gallery {
  margin-top: 16px;
  gap: 8px;
}

img {
  width: 100%;
}

button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 12px;
  color: white;
  height: 100px;
  width: 100%;
  max-width: 200px;
}
</style>
