const menu = [
  {
    icon: 'box',
    label: 'All Blocks',
    value: 'all',
  },
  {
    icon: 'heart-empty',
    label: 'Favorite',
    value: 'favorite',
  },
];

export default menu;
