<template>
    <div class="block">
      <div class="block-content">
        <span>
          <u-icon
            :color="block.favorite ? 'var(--like-color)' : ''"
            :name="block.favorite ? 'heart' : 'heart-empty'"
          />
          <span v-if="title">{{ title }}</span>
          <span class="italic-text" v-else>Untitled</span>
        </span>
      </div>
      <div class="block-content text-small">
        <span><u-icon name="code" /> {{ block.language }}</span>
        <span>{{ datetime }}</span>
      </div>
    </div>
  </template>
  <script setup>
  import { computed, inject } from 'vue';
  
  const props = defineProps({
    block: {
      type: Object,
    },
  });
  const dayjs = inject('dayjs');
  const datetime = computed(() => dayjs(props.block.created_at).calendar());
  const title = computed(() =>
    props.block.title.length >= 27 ? `${props.block.title.slice(0, 30)}..` : props.block.title
  );
  </script>
  <style scoped>
  .block {
    cursor: pointer;
    color: inherit;
    margin: 5px 0px 5px 0px;
    padding: 1px 5px 1px 5px;
  }
  
  .block-content {
    display: flex;
    justify-content: space-between;
  }
  
  .text-small {
    font-size: 12px;
  }
  
  .italic-text {
    font-style: italic;
  }
  </style>
  